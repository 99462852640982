import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer'
import logo from '../../../assets/aajLogo-pdf.png'

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: 20,
    backgroundColor: '#fff'
  },
  header: {
    marginBottom: 20
  },
  logo: {
    width: 80,
    height: 'auto',
    objectFit: 'contain'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  addressBlock: {
    width: '48%'
  },
  addressTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#374151'
  },
  addressText: {
    fontSize: 11,
    marginBottom: 3,
    color: '#4b5563'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    marginTop: 10
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '25%',
    backgroundColor: '#0C1A57',
    color: '#fff',
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    textAlign: 'center'
  },
  tableCol: {
    width: '25%',
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    textAlign: 'center'
  },
  tableCell: {
    fontSize: 10
  }
})

const PackingListSheet = ({ items, trackingId, destinationHubAddress }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <View style={styles.title}>
            <Text>Master Packing List</Text>
          </View>
        </View>

        {/* Addresses */}
        <View style={styles.section}>
          <View style={styles.addressBlock}>
            <Text style={styles.addressTitle}>Sender</Text>
            <Text style={styles.addressText}>AAJ Express Logistics</Text>
            <Text style={styles.addressText}>13 Oguntana Cresent, Pedro</Text>
            <Text style={styles.addressText}>Gbagada Phase I</Text>
          </View>
          <View style={styles.addressBlock}>
            <Text style={styles.addressTitle}>Receiver</Text>
            <Text style={styles.addressText}>AAJ Express Logistics</Text>
            <Text style={styles.addressText}>{destinationHubAddress[0]}</Text>
            <Text style={styles.addressText}>{destinationHubAddress[1]}</Text>
          </View>
          <View style={styles.addressBlock}>
            <Text style={styles.addressTitle}>Tracking Number</Text>
            <Text style={styles.addressText}>{trackingId}</Text>
          </View>
        </View>

        {/* Table */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableColHeader, styles.tableCell]}>S/N</Text>
            <Text style={[styles.tableColHeader, styles.tableCell]}>
              Item Name
            </Text>
            <Text style={[styles.tableColHeader, styles.tableCell]}>
              Quantity
            </Text>
            <Text style={[styles.tableColHeader, styles.tableCell]}>
              Unit Weight (Kg)
            </Text>
          </View>
          {/* Table Rows */}
          {items?.map((item, idx) => (
            <View style={styles.tableRow} key={idx}>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {idx > 9 ? idx + 1 : `0${idx + 1}`}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {item.name}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {item.quantity}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {item.weight}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}

export default PackingListSheet
