import React from 'react'
import { Field } from 'formik'
import ComboBox from '../ComboBox'

const AppFormComboBox = ({
  name,
  title,
  labelButton,
  options,
  handleOnChange = () => {},
  disabled = false
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <div className='form-control w-full text-sm'>
            <label className='label'>
              <span className='label-text'>{title}</span>
              {labelButton}
            </label>
            <ComboBox
              title={title}
              value={
                field.value
                  ? options.find(opt => opt.value === field.value)
                  : ''
              }
              options={options}
              onChange={selected => {
                form.setFieldValue(field.name, selected?.value)
                handleOnChange(selected?.value, field.name)
              }}
              disabled={disabled}
            />
            {meta.touched && meta.error ? (
              <p className='text-error text-xs'>{meta.error}</p>
            ) : null}
          </div>
        )
      }}
    </Field>
  )
}

export default AppFormComboBox
