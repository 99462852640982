import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadUrlDoc, parseError } from '../../../utils'
import { Link, useParams } from 'react-router-dom'
import { PiPrinterLight } from 'react-icons/pi'
import { IoMdAdd } from 'react-icons/io'
import { Button, Table } from '../../../components'
import useToast from '../../../hooks/useToast'
import Page from '../../../containers/Page'
import manifestApi from '../../../api/manifests'
import { ExceptionIcon, AssignUserIcon } from '../../../components/icons'
import ROUTES from '../../../constants/routes'
import { CircularProgress } from '@mui/material'
import Search from '../../../components/globals/Search/Search'
import SearchResultsDescription from '../../../components/globals/Search/SearchResultsDescription'
import { AiOutlineDelete } from 'react-icons/ai'
import { AssignToAstro, AssignToAstrosMenu } from '../../../components/astros'
import { ConfirmPrompt, Loader, Pill } from '../../../components/globals'
import RaiseException from '../../../components/exception/RaiseException'
import Pagination from '../../../components/globals/pagination/ServerPagination'
import {
  fetchInternationalManifest,
  manifestsActions
} from '../../../slices/manifestsSlice'
import AddShipmentsToInternationalManifest from '../../../components/manifests/international/AddShipmentsToInternalManifest'
import { MdOutlinePreview } from 'react-icons/md'
import ScanEvent from '../../../components/scan/ScanEvent'
import useCheckedList from '../../../hooks/useCheckedList'
import { statuses } from '../../../fixtures/manifestStatus'

const tableHeader = ['S/N', 'Shipment', 'Action']

const IndividualInternationalManifest = ({ metaTitle }) => {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'International Manifests',
      path: ROUTES.MANIFESTS.INTERNATIONAL.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: '#',
      disabled: true
    }
  ]
  const dispatch = useDispatch()
  const toast = useToast()

  const manifestsStore = useSelector(state => state.manifests.international._id)

  const [search, setSearch] = useState({
    by: 'tracking_id',
    value: ''
  })
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [raiseException, setRaiseException] = useState({
    isOpen: false,
    type: ''
  })
  const [activeShipment, setActiveShipment] = useState('')
  const [isRemoveShipment, setRemoveShipment] = useState({
    isOpen: false,
    isLoading: false,
    type: ''
  })
  const [isAddShipments, setAddShipments] = useState(false)
  const [isAssign, setAssign] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isUpdateStatus, setUpdateStatus] = useState(false)
  const [isDownloadingLabel, setDownloadingLabel] = useState({
    state: false,
    type: '',
    id: ''
  })
  const { checkedList, setCheckedList, selectedItems } = useCheckedList({
    serializedData
  })

  const loadManifest = useCallback(() => {
    const promise = dispatch(fetchInternationalManifest({ id, queryParams }))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const shipments = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const responses = serializedData.filter(shipment =>
          shipment[search.by].includes(search.value)
        )

        return responses
      }

      return serializedData
    } else return null
  }, [search, serializedData])

  const tableData = useMemo(() => {
    return shipments?.map((shipment, id) => ({
      'S/N': id + 1,
      Shipment: (
        <div>
          <div className='flex items-center gap-1'>
            <Link
              to={`${ROUTES.SHIPMENTS.path}?id=${shipment.tracking_id}`}
              onClick={e => e.stopPropagation()}
              className='hover:text-primary hover:underline'
            >
              {shipment.tracking_id.toUpperCase()}{' '}
            </Link>
            {shipment.child && (
              <Pill
                size='sm'
                rounded='sm'
                name='CHILD'
                theme={{
                  primary: '#484848',
                  secondary: '#EFF1F3'
                }}
                border={false}
              />
            )}
          </div>

          {shipment.child && (
            <div className='text-2xs'>Master: {shipment.master_shipment}</div>
          )}
        </div>
      ),
      Action: (
        <>
          {!shipment.child && (
            <button
              className='btn btn-primary btn-sm btn-outline'
              onClick={() => handleDownloadSingleShipmentLabel(shipment)}
            >
              <PiPrinterLight /> Print Label
              {isDownloadingLabel.state &&
                isDownloadingLabel.type === 'single' &&
                isDownloadingLabel.id === shipment.tracking_id && (
                  <CircularProgress color='inherit' />
                )}
            </button>
          )}
        </>
      ),
      ...shipment
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments])

  const credentials = useMemo(
    () => [
      {
        key: 'Orginating Hub',
        value: manifestsStore.data?.originating_hub
      },
      {
        key: 'Destination Hub',
        value: manifestsStore.data?.meta.destination
      },
      {
        key: 'Astro/Captain',
        value: manifestsStore.data?.assigned_to?.name || (
          <span className='italic'>Unassigned</span>
        )
      }
    ],
    [manifestsStore.data]
  )

  useEffect(() => {
    return () => {
      dispatch(
        manifestsActions.cleanup({
          type: 'international',
          _id: true
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadManifest()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadManifest])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const rowMenuItems = row => [
    {
      name: (
        <AssignToAstrosMenu
          type='SHIPMENT'
          data={{
            id: row.tracking_id
          }}
        />
      ),
      icon: AssignUserIcon,
      action: (row, closePanel) => {
        setActiveShipment(row)
      }
    },
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveShipment(row)
        setRaiseException({ isOpen: true, type: 'SHIPMENT' })
      }
    },
    {
      name: 'Remove Shipment',
      icon: AiOutlineDelete,
      action: () => {
        setActiveShipment(row)
        setRemoveShipment({ isOpen: true, isLoading: false, type: 'single' })
      },
      color: 'danger'
    }
  ]

  const handleExceptionModalClose = () => {
    setRaiseException({ isOpen: false, type: '' })
    setActiveShipment(null)
  }

  const handleSearch = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [search.by]: search.value }))
    setServerSearch({
      searchBy: search.by,
      searchValue: search.value
    })
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const handleAddShipments = () => {
    setAddShipments(true)
  }

  const handleAddShipmentsClose = ({ isSuccess }) => {
    setAddShipments(false)
    if (isSuccess) {
      loadManifest()
    }
  }

  const handleAssign = () => {
    if (!manifestsStore.data?.meta.shipments.length) {
      toast('Manifest is empty!', 'error')
      return
    }
    setAssign(true)
  }

  const handleAssignClose = ({ isSuccess }) => {
    setAssign(false)
    if (isSuccess) {
      loadManifest()
    }
  }

  const handleRemoveShipment = async () => {
    if (isRemoveShipment.isLoading) return

    setRemoveShipment(state => ({ ...state, isLoading: true }))

    const shipments =
      isRemoveShipment.type === 'multiple'
        ? selectedItems.map(({ tracking_id }) => tracking_id)
        : [activeShipment.tracking_id]

    const response = await manifestApi.removeShipment({
      manifest_id: id,
      shipments,
      manifest_type: 'IM'
    })

    setRemoveShipment(state => ({ ...state, isLoading: false }))

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        const message =
          typeof apiError.data === 'string'
            ? apiError.data
            : apiError.data.errors[0].detail
        toast(message, 'error')
      }
      return
    }

    toast(`Shipment${shipments.length > 1 ? '' : 's'} removed`, 'success')
    closeRemoveShipmentPrompt()
    loadManifest()
  }

  const closeRemoveShipmentPrompt = () => {
    setActiveShipment(null)
    setRemoveShipment({ isOpen: false, isLoading: false, type: '' })
  }

  const publishManifest = async () => {
    if (!manifestsStore.data?.meta.shipments.length) {
      toast('Manifest is empty!', 'error')
      return
    }

    setLoading(true)
    const response = await manifestApi.publishManifest(tracking_id)

    setLoading(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Manifest published', 'success')
    loadManifest()
  }

  const downloadSelectedShipmentsLabels = async () => {
    if (isDownloadingLabel.state) return

    if (!selectedItems.length) {
      toast('No label found', 'error')
      return
    }

    setDownloadingLabel({ state: true, type: 'multiple', id: '' })

    for (const shipment of selectedItems) {
      try {
        await downloadShipmentLabel(shipment)
      } catch (error) {
        console.error('Error downloading label document:', error)
      }
    }

    setDownloadingLabel({ state: false, type: '', id: '' })
  }

  const downloadShipmentLabel = async shipment => {
    if (!shipment.label) {
      toast(`${shipment.tracking_id}: Document not found`, 'error')
      return
    }

    for (const label of shipment.label) {
      const docName = `${shipment.tracking_id}_SHIPPO_label.pdf`
      await downloadUrlDoc(label, docName)
    }

    if (shipment.courier_commercial_invoice) {
      const docName = `${shipment.tracking_id}_COURIER_COMMERCIAL_INVOICE.pdf`
      await downloadUrlDoc(shipment.courier_commercial_invoice, docName)
    }
  }

  const handleDownloadSingleShipmentLabel = async shipment => {
    if (isDownloadingLabel.state) return

    setDownloadingLabel({
      state: true,
      type: 'single',
      id: shipment.tracking_id
    })

    try {
      await downloadShipmentLabel(shipment)
    } catch (error) {
      console.error('Error downloading label document:', error)
    }

    setDownloadingLabel({
      state: false,
      type: '',
      id: ''
    })
  }

  const handleOpenScanEvent = () => {
    if (!manifestsStore.data?.meta.shipments.length) {
      toast('Manifest is empty!', 'error')
      return
    }
    setUpdateStatus(true)
  }

  const handleScanEventClose = ({ isSuccess }) => {
    if (isSuccess) {
      loadManifest()
    }
    setUpdateStatus(false)
  }

  return (
    <Page metaTitle={`${tracking_id.toUpperCase()} - ${metaTitle}`}>
      <Page.Header
        title={tracking_id.toUpperCase()}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.INTERNATIONAL.path}
      >
        {manifestsStore.data && (
          <Pill
            name={statuses[manifestsStore.data?.state].name}
            theme={statuses[manifestsStore.data?.state].theme}
          />
        )}

        <Search
          value={search.value}
          searchBy={search.by}
          inputPlaceHolder='Search by shipment ID'
          handleSearch={handleSearch}
          allowServerSearch={false}
          onServerSearch={handleServerSearch}
        />
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
          </div>
          <div className='flex lg:hidden ml-auto'>
            <Pagination
              tableId={`${tracking_id}-shipments-table`}
              pageSize={manifestsStore.meta?.page_size}
              totalCount={manifestsStore.meta?.count}
              data={manifestsStore.data?.meta.shipments}
              setSerializedData={setSerializedData}
              onPage={onPage}
              page={manifestsStore.meta?.page}
            />
          </div>
        </div>
        <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
          <div></div>
          <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
            <div className='hidden lg:flex'>
              <Pagination
                tableId={`${tracking_id}-shipments-table`}
                pageSize={manifestsStore.meta?.page_size}
                totalCount={manifestsStore.meta?.count}
                data={manifestsStore.data?.meta.shipments}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={manifestsStore.meta?.page}
              />
            </div>
            <Button
              variant='neutral'
              text='Raise Exception'
              onClick={() =>
                setRaiseException({ isOpen: true, type: 'MANIFEST' })
              }
              icon={<ExceptionIcon width={14} height={14} />}
            />
            {manifestsStore.data?.state === 'DRAFT' && (
              <Button
                variant='neutral'
                text='Add Shipment'
                onClick={handleAddShipments}
                icon={<IoMdAdd />}
              />
            )}
            {manifestsStore.data?.state === 'DRAFT' && (
              <Button
                variant='neutral'
                text={
                  <>
                    Publish
                    {loading && <CircularProgress size={14} color='inherit' />}
                  </>
                }
                onClick={publishManifest}
              />
            )}
            {manifestsStore.data?.state === 'PUBLISH' && (
              <Button
                variant='neutral'
                text='Update Status'
                onClick={handleOpenScanEvent}
              />
            )}
            {manifestsStore.data?.state === 'PUBLISH' && (
              <Link
                to={
                  !manifestsStore.data?.meta.shipments.length
                    ? '#'
                    : `${ROUTES.MANIFESTS.INTERNATIONAL.path}${id}/packing-list`
                }
              >
                <Button variant='neutral' text='Packing List' />
              </Link>
            )}
            <Link
              to={
                !manifestsStore.data?.meta.shipments.length
                  ? '#'
                  : `${ROUTES.MANIFESTS.INTERNATIONAL.path}${id}/sheet`
              }
            >
              <Button
                variant='neutral'
                text='Preview'
                icon={<MdOutlinePreview />}
              />
            </Link>
            <Button
              text={manifestsStore.data?.assigned_to ? 'Reassign' : 'Assign'}
              icon={<AssignUserIcon stroke='white' width='14' />}
              onClick={handleAssign}
            />
          </div>
        </div>

        {!manifestsStore.data ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            <div>
              <div className='text-sm py-4 flex w-full flex-col md:flex-row md:gap-10 items-start'>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(0, 3).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-all'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {selectedItems.length ? (
              <div className='text-sm mb-2'>
                <div className='flex gap-2 items-center'>
                  <p>
                    Selected {selectedItems.length} item
                    {selectedItems.length > 1 ? 's' : ''}
                  </p>
                  •
                  <button
                    className='p-1 btn btn-primary btn-outline btn-sm'
                    onClick={downloadSelectedShipmentsLabels}
                  >
                    <PiPrinterLight />
                    Print Labels
                    {isDownloadingLabel.state &&
                      isDownloadingLabel.type === 'multiple' && (
                        <CircularProgress color='inherit' />
                      )}
                  </button>
                  <button
                    className='p-1 btn btn-error btn-outline btn-sm'
                    onClick={() =>
                      setRemoveShipment({
                        isOpen: true,
                        isLoading: false,
                        type: 'multiple'
                      })
                    }
                  >
                    <AiOutlineDelete />
                    Remove Shipments
                    {isRemoveShipment.isLoading &&
                      isRemoveShipment.type === 'multiple' && (
                        <CircularProgress color='inherit' />
                      )}
                  </button>
                </div>
              </div>
            ) : null}
            <Table
              id={`${tracking_id}-shipments-table`}
              headers={tableHeader}
              data={tableData}
              emptyDataText='No shipment found in manifest'
              emptyDataActionText='Add Shipment'
              emptyDataAction={handleAddShipments}
              withMenu
              rowMenuItems={rowMenuItems}
              withCheckbox
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          </>
        )}

        {isAddShipments && (
          <AddShipmentsToInternationalManifest
            isOpen={isAddShipments}
            onClose={handleAddShipmentsClose}
            manifest={{
              id,
              carrier: manifestsStore.data?.meta?.tpl_service,
              originating_hub: manifestsStore.data?.originating_hub,
              destination_hub: manifestsStore.data?.destination_hub
            }}
          />
        )}
        {isAssign && (
          <AssignToAstro
            isOpen={isAssign}
            data={{
              manifest_type: 'IM',
              id
            }}
            onClose={handleAssignClose}
            type='MANIFEST'
          />
        )}
        {raiseException.isOpen && (
          <RaiseException
            isOpen={raiseException.isOpen}
            onClose={handleExceptionModalClose}
            id={
              raiseException.type === 'SHIPMENT'
                ? activeShipment.tracking_id
                : tracking_id
            }
            type={raiseException.type}
          />
        )}

        {isRemoveShipment.isOpen && (
          <ConfirmPrompt
            isOpen={isRemoveShipment.isOpen}
            onConfirm={handleRemoveShipment}
            onCancel={closeRemoveShipmentPrompt}
            title='Confirm Removal'
            text={`Are you sure you want to remove ${
              isRemoveShipment.type === 'multiple'
                ? 'the selected shipments'
                : 'this shipment'
            } from the manifest?`}
            contentLabel='confirm remove shipment'
            isLoading={isRemoveShipment.isLoading}
          />
        )}
        {isUpdateStatus && (
          <ScanEvent
            isOpen={isUpdateStatus}
            onClose={handleScanEventClose}
            type='MANIFEST'
            id={tracking_id}
          />
        )}
      </Page.Body>
    </Page>
  )
}

export default IndividualInternationalManifest
