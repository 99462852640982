import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react'
import { useMemo, useState } from 'react'
import { HiChevronDown } from 'react-icons/hi2'
import { MdCheck } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

export default function ComboBox ({
  title,
  value,
  options = [],
  onChange = () => {},
  disabled = false,
  style
}) {
  const [query, setQuery] = useState('')

  const filteredOptions = useMemo(() => {
    return query === ''
      ? options
      : options.filter(option =>
          option.name.toLowerCase().includes(query.toLowerCase())
        )
  }, [options, query])

  const inputStyle = useMemo(() => {
    return Object.assign({ height: '2.5rem' }, style)
  }, [style])

  return (
    <Combobox value={value} onChange={onChange} as='div' className='relative'>
      <div
        style={inputStyle}
        className={
          'relative bg-white border border-[#CBCBCB] rounded-lg h-10 pl-3 pr-10 overflow-hidden w-full'
        }
      >
        <ComboboxInput
          aria-label={title}
          displayValue={option => option?.name}
          onChange={({ target }) => setQuery(target.value)}
          className='w-full h-full px-0 border-none outline-0'
          disabled={disabled}
        />
        <ComboboxButton className='group absolute inset-y-0 right-0 px-2.5 hover:bg-main-hover hover:text-main-primary'>
          <HiChevronDown size={14} />
        </ComboboxButton>
      </div>
      <ComboboxOptions
        transition
        className={twMerge(
          'absolute top-full mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black/5 p-1 empty:invisible max-h-40 overflow-y-auto z-20',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
        as='ul'
      >
        {filteredOptions.map(option => (
          <ComboboxOption
            as='li'
            key={option.value}
            value={option}
            className='data-[focus]:bg-main-hover cursor-pointer data-[focus]:text-main-primary-2 py-1.5 px-2'
          >
            {({ selected }) => (
              <div className='flex w-full items-center justify-between gap-1'>
                <div>{option.name}</div>
                {selected && (
                  <span className='pointer-events-none ml-auto flex items-center'>
                    <MdCheck size={14} color='#fe6802' />
                  </span>
                )}
              </div>
            )}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  )
}
