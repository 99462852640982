import { useMemo, useState, useContext, useCallback, useEffect } from 'react'
import Filter from '../../../components/globals/filter/Filter'
import Search from '../../../components/globals/Search/Search'
import Page from '../../../containers/Page'
import {
  exportCSVData,
  getDate,
  initializeDateRange,
  parseError
} from '../../../utils'
import DemurragesFilter from '../../../components/transactions/demurrages/DemurragesFilter'
import ExportDialogue from '../../../components/globals/export/ExportDialogue'
import { useDispatch, useSelector } from 'react-redux'
import useToast from '../../../hooks/useToast'
import transactionsApi from '../../../api/transactions'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import FilterTag from '../../../components/globals/filter/FilterTag'
import Pagination from '../../../components/globals/pagination/ServerPagination'
import SearchResultsDescription from '../../../components/globals/Search/SearchResultsDescription'
import { MdOutlineFileDownload } from 'react-icons/md'
import { CircularProgress } from '@mui/material'
import { Pill, Table } from '../../../components'
import { fetchDemurrages } from '../../../slices/transactionSlice'
import { resolveDateParams } from '../../../helpers/queryByDate'
import { Link } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import { fetchHubs } from '../../../slices/hubsSlice'

const searchOptions = [
  {
    name: 'Shipment',
    value: 'shipment_id'
  },
  {
    name: 'Customer',
    value: 'customer_name'
  }
]
const tableHeader = [
  'S/N',
  'Shipment ID',
  'Date Arrived',
  'Customer',
  'Pickup Hub',
  'Overdue Duration',
  'Accrued Amount',
  'Status'
]

const currencies = [
  { name: 'NGN (₦)', value: 'NGN', symbol: '₦' },
  {
    name: 'USD ($)',
    value: 'USD',
    symbol: '$'
  },
  {
    name: 'GBP (£)',
    value: 'GBP',
    symbol: '£'
  },
  {
    name: 'EUR (€)',
    value: 'EUR',
    symbol: '€'
  }
]

export default function Demurrages ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)
  const userData = useSelector(state => state.auth.user)
  const demurragesStore = useSelector(state => state.transactions.demurrages)

  const [isExportOpen, setExportOpen] = useState(false)
  const [search, setSearch] = useState({
    by: searchOptions[0].value,
    value: ''
  })
  const [serializedData, setSerializedData] = useState(null)
  const [serverSearch, setServerSearch] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    date: '',
    status: '',
    pickup_hub: ''
  })
  const [filterTags, setFilterTags] = useState([])
  const [dateRange, setDateRange] = useState(initializeDateRange())
  const [isExportLoading, setExportLoading] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  const loadDemurrages = useCallback(() => {
    const promise = dispatch(fetchDemurrages(queryParams))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const searchInputPlaceHolder = useMemo(() => {
    return search.by === 'customer_name'
      ? 'Enter customer name'
      : 'Enter Shipment ID'
  }, [search.by])

  const demurrages = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter(demurrage =>
          demurrage[search.by]
            .toLowerCase()
            .includes(search.value.toLowerCase())
        )
        return results
      }

      return serializedData
    } else return null
  }, [search, serializedData])

  const tableData = useMemo(() => {
    return demurrages?.map(demurrage => ({
      ...demurrage,
      'S/N': demurrage.s_n,
      'Shipment ID': (
        <Link
          to={`${ROUTES.SHIPMENTS.path}?id=${demurrage.shipment_id}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          {demurrage.shipment_id}
        </Link>
      ),
      'Date Arrived': getDate(demurrage.date_arrived),
      Customer: demurrage.customer_name,
      'Pickup Hub': demurrage.pickup_hub,
      'Overdue Duration': `${Number(demurrage.overdue_duration)} Days`,
      'Accrued Amount': `${
        currencies.find(({ value }) => value === demurrage.currency)?.symbol
      } ${demurrage.accrued_amount}`,
      Status: (
        <Pill
          name={demurrage.status}
          theme={{
            primary: demurrage.status === 'Paid' ? '#00AB44' : '#FFC700',
            secondary: demurrage.status === 'Paid' ? '#00AB4433' : '#FFF6D799'
          }}
        />
      )
    }))
  }, [demurrages])

  useEffect(() => {
    dispatch(fetchHubs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadDemurrages()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadDemurrages])

  useEffect(() => {
    const params = {}
    const tags = []
    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else {
          params[key] = filter[key]
          tags.push({ name: key, value: filter[key].replaceAll('_', ' ') })
        }
      }
    }
    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const onSearchChange = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleSearchOptionChange = option => {
    setSearch({ by: option, value: '' })
    if (serverSearch) {
      setQueryParams(state => {
        delete state[search.by]
        return state
      })
      setServerSearch(null)
    }
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({
      ...state,
      [search.by]: search.value
    }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === search.by)?.name,
      searchValue: search.value
    })
    setSearch(state => ({ ...state, value: '' }))
  }

  const exportTransactions = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await transactionsApi.exportTransactions(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `demurrages_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'demurrages.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportTransactions(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const handleExportClose = () => {
    setExportOpen(false)
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Demurrages'}>
        <Search
          value={search.value}
          multiple={true}
          searchBy={search.by}
          searchOptions={searchOptions}
          onSearchOptionChange={handleSearchOptionChange}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={DemurragesFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Page.Header>
      <Page.Body>
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='demurrages'
            onClose={handleExportClose}
            options={queryParams}
            onExport={exportTransactions}
          />
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='demurrages-table'
                pageSize={demurragesStore.meta?.page_size}
                totalCount={demurragesStore.meta?.count}
                data={demurragesStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={demurragesStore.meta?.page}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='demurrages-table'
                  pageSize={demurragesStore.meta?.page_size}
                  totalCount={demurragesStore.meta?.count}
                  data={demurragesStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={demurragesStore.meta?.page}
                />
              </div>
              <button
                className='btn'
                onClick={handleExport}
                disabled={!demurrages?.length}
              >
                <MdOutlineFileDownload size={18} />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress size={18} color='inherit' />
                  </>
                ) : (
                  'Export'
                )}
              </button>
            </div>
          </div>
        </div>
        <Table
          id='demurrages-table'
          headers={tableHeader}
          data={tableData}
          emptyDataText='No demurrage found'
        />
      </Page.Body>
    </Page>
  )
}
