import useDocumentTitle from '../hooks/useDocumentTitle'
import { useContext, useEffect, useRef } from 'react'
import TopNav from '../components/globals/TopNav'
import { BackButton } from '../components'
import { headerShadowOnScroll } from '../utils/animate'
import { AuthLayoutContext } from './AuthLayout'
import BreadCrumb from '../components/globals/BreadCrumb'
import { twMerge } from 'tailwind-merge'

export default function Page ({ metaTitle, className, children }) {
  useDocumentTitle(`${metaTitle} | AAJ Transport Pro`)

  const { layoutContainer } = useContext(AuthLayoutContext)

  useEffect(() => {
    if (layoutContainer) {
      layoutContainer.scrollTo(0, 0)
    }
  }, [layoutContainer])

  return (
    <div aria-describedby='page-container' className={twMerge(className)}>
      {children}
      <Page.Footer />
    </div>
  )
}

Page.Header = function PageHeader ({
  title,
  transparent,
  withBack,
  backRoute,
  onBack,
  breadCrumbs,
  children
}) {
  const headerRef = useRef()

  useEffect(() => {
    if (headerRef.current) {
      headerShadowOnScroll(headerRef.current)
    }
  }, [])

  return (
    <div
      aria-describedby='page header'
      className={twMerge('sticky-header z-20', transparent ? '' : 'bg-g-200')}
      ref={headerRef}
    >
      <TopNav />
      <div className='space-y-2 px-4 py-2'>
        <div className='flex flex-col md:flex-row gap-2 items-start flex-wrap'>
          <div>
            <div className='flex items-center gap-2 w-full md:w-fit'>
              {withBack && <BackButton to={backRoute} onBack={onBack} />}
              {title && <h1 className='font-bold text-2xl'>{title}</h1>}
            </div>
            {breadCrumbs && <BreadCrumb links={breadCrumbs} />}
          </div>

          <div className='flex justify-end items-center gap-2 md:gap-3 ml-auto flex-wrap'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Page.Body = function PageBody ({ children }) {
  return (
    <div
      aria-describedby='page body'
      className='px-4 pb-20 min-h-[80vh]'
    >
      {children}
    </div>
  )
}

Page.Footer = function PageFooter () {
  const year = new Date().getFullYear()
  return (
    <div
      aria-describedby='page footer'
      className='px-4 pb-4 text-sm text-center text-g-1000 mt-auto mx-auto'
    >
      &copy; AAJ Transport Pro {year}
    </div>
  )
}
