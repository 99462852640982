import { useParams } from 'react-router-dom'
import Page from '../../../containers/Page'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Loader } from '../../../components'
import manifestsApi from '../../../api/manifests'
import useToast from '../../../hooks/useToast'
import PackingListPDFSheet from '../../../components/manifests/components/PackingListPDFSheet'
import { parseError } from '../../../utils'
import { CircularProgress } from '@mui/material'
import logo from '../../../assets/aajLogo.png'
import ROUTES from '../../../constants/routes'
import { FiDownload } from 'react-icons/fi'
import useApi from '../../../hooks/useApi'
import { countryCodesPerHub } from '../../../fixtures/countryCodesPerHub'
import { useSelector } from 'react-redux'
import lodash from 'lodash'
import ToggleSwitch from '../../../components/globals/ToggleSwitch'
import { IoMdAdd } from 'react-icons/io'
import { ComboBox } from '../../../components/globals'
import { AiOutlineDelete } from 'react-icons/ai'
import { AuthLayoutContext } from '../../../containers/AuthLayout'

const PackingListHTML = ({
  items,
  trackingId,
  destinationHubAddress,
  onUpdateItems,
  isEdit,
  editableItems,
  setEditableItems
}) => {
  const [loading, setLoading] = useState(false)
  const [showItemCategories, setShowItemCategories] = useState(false)
  const [itemCategory, setItemCategory] = useState(null)

  const itemsStore = useSelector(state => state.items.data)
  const categories = useSelector(state => state.items.categories)

  const selectableItems = useMemo(() => {
    if (itemCategory) {
      return itemsStore.filter(item => {
        return item.category === itemCategory && !item.excludePackingList
      })
    }
    return []
  }, [itemCategory, itemsStore])

  const handleItemCategoryChange = category => {
    setItemCategory(category.value)
    const newItem = {
      id: null,
      name: '',
      quantity: 1,
      weight: 0.1,
      total_weight: 1.1
    }
    const updatedItems = [...editableItems, newItem]
    setEditableItems(updatedItems)
    setShowItemCategories(false)
  }

  const handleChange = (index, field, value) => {
    const updatedItems = [...editableItems]

    switch (field) {
      case 'quantity':
      case 'weight':
      case 'price':
        updatedItems[index][field] = Number(value)
        updatedItems[index].total_weight =
          updatedItems[index].quantity * updatedItems[index].weight
        updatedItems[index].total_price =
          updatedItems[index].quantity * updatedItems[index].price
        break
      default:
        updatedItems[index][field] = value
        break
    }
    setEditableItems(updatedItems)
  }

  const handleDelete = index => {
    const updatedItems = lodash.cloneDeep(editableItems)
    updatedItems.splice(index, 1)
    setEditableItems(updatedItems)
  }

  const handleSave = e => {
    e.preventDefault()

    setLoading(true)
    onUpdateItems(editableItems, (isSuccess, isError) => {
      setLoading(false)
      if (isError) {
        setEditableItems(lodash.cloneDeep(items))
      }
    })
  }

  const handleCancel = () => {
    setEditableItems(lodash.cloneDeep(items))
    setShowItemCategories(false)
  }

  const onAdd = () => {
    setShowItemCategories(true)
  }

  return (
    <div className='max-w-3xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg p-4 md:p-6 overflow-auto min-h-[80vh] text-[13px]'>
      <div>
        <img src={logo} alt='AAJ Express Logo' className='w-20' />
        <h2 className='text-xl font-bold text-gray-800 text-center mt-6'>
          Master Packing List
        </h2>
      </div>

      <div className='flex flex-row justify-between gap-4 mt-10'>
        <div>
          <h3 className='text-base font-medium text-gray-700'>Sender</h3>
          <p className='text-gray-600'>AAJ EXPRESS LOGISTICS LTD</p>
          <p className='text-gray-600'>13 Oguntana Cresent, Pedro</p>
          <p className='text-gray-600'>Gbagada Phase I</p>
        </div>
        <div>
          <h3 className='text-base font-medium text-gray-700'>Receiver</h3>
          <p className='text-gray-600'>AAJ EXPRESS INC</p>
          <p className='text-gray-600'>{destinationHubAddress[0]}</p>
          <p className='text-gray-600'>{destinationHubAddress[1]}</p>
        </div>
        <div>
          <h3 className='text-base font-medium text-gray-700'>
            Tracking Number
          </h3>
          <p className='text-gray-600'>{trackingId}</p>
        </div>
      </div>
      <form onSubmit={handleSave} id='edit-packing-list-form'>
        <div className='mt-12 overflow-x-auto'>
          <table className='w-full border-collapse border border-gray-200'>
            <thead>
              <tr className='bg-[#0C1A57] text-white'>
                <th className='border border-gray-200 px-2 py-2 text-left font-medium'>
                  S/N
                </th>
                <th className='border border-gray-200 px-2 py-2 text-left font-medium'>
                  Item Name
                </th>
                <th className='border border-gray-200 px-2 py-2 text-left font-medium'>
                  Quantity
                </th>
                <th className='border border-gray-200 px-2 py-2 text-left font-medium'>
                  Unit Weight (kg)
                </th>
                {isEdit && (
                  <th className='border border-gray-200 px-2 py-2 text-left font-medium'>
                    Price
                  </th>
                )}
                {isEdit && (
                  <th className='border border-gray-200 px-2 py-2 text-left font-medium'></th>
                )}
              </tr>
            </thead>
            <tbody className='text-gray-700'>
              {editableItems.map((item, idx) => (
                <tr key={idx}>
                  <td className='border border-gray-200 px-2 py-2'>
                    {idx + 1}
                  </td>
                  <td className='border border-gray-200 px-2 py-2'>
                    {!isEdit ? (
                      item.name
                    ) : !item.name ? (
                      <ComboBox
                        title='Item'
                        value={item.id}
                        options={selectableItems.map(item => ({
                          name: item.name,
                          value: item.id
                        }))}
                        onChange={selected => {
                          handleChange(idx, 'id', selected?.value)
                          handleChange(idx, 'name', selected?.name)
                          handleChange(idx, 'hsCode', selected?.hsCode)
                          handleChange(
                            idx,
                            'manufacturerCountry',
                            selected?.manufacturerCountry
                          )
                          handleChange(
                            idx,
                            'unitMeasurement',
                            selected?.unitMeasurement
                          )
                        }}
                        disabled={loading}
                        style={{ height: '2rem' }}
                      />
                    ) : (
                      <input
                        type='text'
                        value={item.name}
                        onChange={e =>
                          handleChange(idx, 'name', e.target.value)
                        }
                        className='w-full border border-gray-300 rounded px-2 py-1 text-[13px]'
                        required
                      />
                    )}
                  </td>
                  <td className='border border-gray-200 px-2 py-2'>
                    {!isEdit ? (
                      item.quantity
                    ) : (
                      <input
                        type='number'
                        value={item.quantity}
                        onChange={e =>
                          handleChange(idx, 'quantity', e.target.value)
                        }
                        className='w-full border border-gray-300 rounded px-2 py-1 text-[13px]'
                        required
                      />
                    )}
                  </td>
                  <td className='border border-gray-200 px-2 py-2'>
                    {!isEdit ? (
                      item.weight
                    ) : (
                      <input
                        type='number'
                        value={item.weight}
                        onChange={e =>
                          handleChange(idx, 'weight', e.target.value)
                        }
                        className='w-full border border-gray-300 rounded px-2 py-1 text-[13px]'
                        required
                      />
                    )}
                  </td>
                  {isEdit && (
                    <td className='border border-gray-200 px-2 py-2'>
                      <input
                        type='number'
                        value={item.price}
                        onChange={e =>
                          handleChange(idx, 'price', e.target.value)
                        }
                        className='w-full border border-gray-300 rounded px-2 py-1 text-[13px]'
                        required
                      />
                    </td>
                  )}
                  {isEdit && (
                    <td className='border border-gray-200 px-2 py-2'>
                      <button
                        className='btn-icon text-error hover:bg-error/20 hover:text-error'
                        onClick={() => handleDelete(idx)}
                        type='button'
                        disabled={loading}
                      >
                        <AiOutlineDelete size={16} />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isEdit && (
          <div className='flex justify-between mt-2 gap-2'>
            <div className='flex gap-2'>
              <button onClick={onAdd} type='button' className='btn btn-sm'>
                <IoMdAdd size={16} />
              </button>
              {showItemCategories && (
                <div>
                  <label className='pb-1'>Select category</label>
                  <ComboBox
                    title='Item Category'
                    value={itemCategory}
                    options={categories.map(category => ({
                      name: category,
                      value: category
                    }))}
                    onChange={handleItemCategoryChange}
                    style={{ height: '2rem' }}
                  />
                </div>
              )}
            </div>
            <div className='flex gap-1 items-start'>
              <button
                className='bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 w-16 items-center'
                disabled={loading}
                type='submit'
                form='edit-packing-list-form'
              >
                {loading ? (
                  <CircularProgress size={14} color='inherit' />
                ) : (
                  'Save'
                )}
              </button>
              <button
                onClick={handleCancel}
                className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600'
                disabled={loading}
                type='button'
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default function IndividualInternationalManifestPackingList ({
  metaTitle
}) {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'International Manifests',
      path: ROUTES.MANIFESTS.INTERNATIONAL.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: ROUTES.MANIFESTS.INTERNATIONAL.path + id
    },
    {
      name: 'Packing List',
      path: '#',
      disabled: true
    }
  ]
  const toast = useToast()

  const items = useSelector(state => state.items.data)

  const [packingList, setPackingList] = useState()
  const [isLoadingInvoice, setLoadingInvoice] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const [editableItems, setEditableItems] = useState([])

  const { userRole } = useContext(AuthLayoutContext)

  const { data: manifestData, request: fetchManifest } = useApi(
    manifestsApi.getManifest,
    `IM/${id}`
  )

  const fetchPackingList = useCallback(async () => {
    const response = await manifestsApi.getPackingList(id)

    if (!response.ok) {
      toast('Error fetching packing list', 'error')
      return
    }

    setPackingList(response.data.payload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const destinationHubAddress = useMemo(() => {
    if (manifestData) {
      return countryCodesPerHub[manifestData.payload.destination_hub]?.address
    }
    return []
  }, [manifestData])

  const validItems = useMemo(() => {
    if (packingList) {
      return packingList.items.filter(item => {
        return !items?.find(i => i.id === item.id)?.excludePackingList
      })
    }
    return []
  }, [items, packingList])

  const canEditPackingList = useMemo(() => {
    return userRole.permissions?.manifests.actions.editPackingList
  }, [userRole])

  useEffect(() => {
    fetchPackingList()
    fetchManifest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setEditableItems(lodash.cloneDeep(validItems))
  }, [validItems])

  const handleGenerateCommercialInvoice = async () => {
    setLoadingInvoice(true)

    const response = await manifestsApi.createCommercialInvoice(packingList.id)

    if (!response.ok) {
      setLoadingInvoice(false)
      const apiError = parseError(response)
      toast(
        apiError.data.error || 'Error generating commercial invoice',
        'error'
      )
      return
    }

    const link = document.createElement('a')
    link.target = '_blank'
    link.href = response.data.invoice
    link.download = 'commercial_invoice.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setLoadingInvoice(false)
  }

  const toggleEdit = () => {
    if (isEdit) {
      setEditableItems(lodash.cloneDeep(validItems))
    }
    setEdit(!isEdit)
  }

  const onUpdateItems = async (items, callback) => {
    const response = await manifestsApi.editPackingList(packingList.id, {
      items
    })

    callback(response.ok, !response.ok)

    if (!response.ok) {
      const apiError = parseError(response)
      toast(
        apiError.data.errors[0].detail || 'Error updating packing list',
        'error'
      )
      return
    }

    toast('Packing list updated successfully')
    setPackingList(response.data)
    toggleEdit()
  }

  return (
    <Page metaTitle={`Packing List - ${tracking_id.toUpperCase()}`}>
      <Page.Header
        title={`${tracking_id.toUpperCase()} / Packing List`}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.INTERNATIONAL.path + id}
      >
        <Button
          onClick={handleGenerateCommercialInvoice}
          variant='neutral'
          text={
            <>
              Generate Commercial Invoice
              {isLoadingInvoice && (
                <CircularProgress size={14} color='inherit' />
              )}
            </>
          }
        />
        {packingList && manifestData && (
          <PDFDownloadLink
            document={
              <PackingListPDFSheet
                items={validItems}
                trackingId={tracking_id.toUpperCase()}
                destinationHubAddress={destinationHubAddress}
              />
            }
            fileName='master_packing_list.pdf'
            className='btn btn-primary'
          >
            <FiDownload />
            Download
          </PDFDownloadLink>
        )}
      </Page.Header>
      <Page.Body>
        <div className='pt-4'>
          {!(packingList && manifestData) ? (
            <Loader />
          ) : (
            <div className='grid max-w-5xl mx-auto grid-cols-12'>
              <div className='hidden lg:block col-span-2'></div>
              <div className='col-span-12 lg:col-span-8'>
                {canEditPackingList && (
                  <div className='flex lg:hidden flex-wrap items-center gap-2 justify-end mb-2'>
                    <h4 className='text-sm'>
                      {isEdit ? 'Disable' : 'Enable'} edit:
                    </h4>
                    <ToggleSwitch
                      name='is_edit'
                      enabled={isEdit}
                      onChange={toggleEdit}
                    />
                  </div>
                )}
                <PackingListHTML
                  items={validItems}
                  trackingId={tracking_id.toUpperCase()}
                  destinationHubAddress={destinationHubAddress}
                  onUpdateItems={onUpdateItems}
                  isEdit={isEdit}
                  editableItems={editableItems}
                  setEditableItems={setEditableItems}
                />
              </div>
              <div className='hidden lg:block col-span-2'>
                <div className='sticky top-32 pl-2'>
                  {canEditPackingList && (
                    <div className='flex flex-wrap items-center gap-2'>
                      <h4 className='text-sm'>
                        {isEdit ? 'Disable' : 'Enable'} edit:
                      </h4>
                      <ToggleSwitch
                        name='is_edit'
                        enabled={isEdit}
                        onChange={toggleEdit}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Page.Body>
    </Page>
  )
}
