import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdAdd } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { AssignUserIcon, ExceptionIcon } from '../../../components/icons'
import * as XLSX from 'xlsx'
import { getDate } from '../../../utils'
import { MdOutlineFileDownload } from 'react-icons/md'
import Page from '../../../containers/Page'
import { fetchTransferManifests } from '../../../slices/manifestsSlice'
import { ManifestsContext } from '../../../containers/ManifestsLayout'
import ROUTES from '../../../constants/routes'
import Search from '../../../components/globals/Search/Search'
import Filter from '../../../components/globals/filter/Filter'
import ManifestsFilter from '../../../components/manifests/components/ManifestsFilter'
import SearchResultsDescription from '../../../components/globals/Search/SearchResultsDescription'
import FilterTag from '../../../components/globals/filter/FilterTag'
import Pagination from '../../../components/globals/pagination/ServerPagination'
import Tabs from '../../../components/globals/Tabs'
import { Pill, Table } from '../../../components'
import { AssignToAstrosMenu } from '../../../components/astros'
import RaiseException from '../../../components/exception/RaiseException'
import { statuses } from '../../../fixtures/manifestStatus'

const tableHeader = [
  'S/N',
  'Manifest ID',
  'Date',
  'Origin',
  'Transiting Hub',
  'Bags',
  'Total Declared Value',
  'Weight',
  'Astro/Captain',
  'Status'
]

export default function TransferManifests ({ metaTitle }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tabs } = useContext(ManifestsContext)
  const manifestsStore = useSelector(state => state.manifests.transfer)

  const [searchBy] = useState('tracking_id')
  const [searchValue, setSearchValue] = useState('')
  const [serverSearch, setServerSearch] = useState(null)

  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    destination_hub_name: ''
  })
  const [filterTags, setFilterTags] = useState([])
  const [isRaiseException, setRaiseException] = useState(false)
  const [activeManifest, setActiveManifest] = useState(null)

  const manifests = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        const results = serializedData.filter(manifest => {
          return manifest[searchBy].includes(searchValue)
        })

        return results
      }

      return serializedData
    } else return null
  }, [searchBy, searchValue, serializedData])

  const tableData = useMemo(() => {
    return manifests?.map(manifest => ({
      'S/N': manifest.s_n,
      'Manifest ID': manifest.tracking_id?.toUpperCase(),
      Date: getDate(manifest.created_at),
      Origin: manifest.originating_hub_name,
      'Transiting Hub': manifest.destination_hub_name,
      Bags: manifest.meta?.total_number_of_bags,
      'Total Declared Value':
        manifest.total_shipments_declared_value?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
      Weight: manifest.total_shipments_weight,
      'Astro/Captain': manifest.assigned_to ? (
        <span className='flex gap-1 items-center'>
          <AssignUserIcon variant='primary' width={14} />
          {manifest.assigned_to.name}
        </span>
      ) : (
        ''
      ),
      Status: (
        <Pill
          name={statuses[manifest.state].name}
          theme={statuses[manifest.state].theme}
        />
      ),
      ...manifest
    }))
  }, [manifests])

  const loadManifests = useCallback(() => {
    const promise = dispatch(fetchTransferManifests(queryParams))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadManifests()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadManifests])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        let tag = { name: key, value: filter[key] }
        params[key] = filter[key]
        tags.push(tag)
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)
    setFilterTags(tags)
  }, [filter])

  const handleSearch = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy,
      searchValue
    })
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const onSelectTab = tab => {
    navigate(tab.route)
  }

  const exportManifest = () => {
    // create header
    const header = [
      'S/N',
      'Manifest ID',
      'Date',
      'Origin',
      'Destination',
      'Quantity',
      'Dispatcher',
      'Astro/Captain',
      'Status'
    ]

    // create rows
    const rows = manifests.map((manifest, id) => {
      let sn = id + 1
      return {
        'S/N': sn,
        'Manifest ID': manifest.tracking_id,
        Date: getDate(manifest.created_at),
        Origin: manifest.originating_hub_name,
        Destination: manifest.destination_hub_name,
        Quantity: manifest.meta.total_number_of_bags,
        Dispatcher: manifest.dispatcher?.name,
        'Astro/Captain': manifest.assigned_to?.name,
        Status: manifest.state
      }
    })

    // generate worksheet and workbook
    const sheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet, 'Manifest')

    // fix headers
    XLSX.utils.sheet_add_aoa(sheet, [header], { origin: 'A1' })

    // column width
    const max_width = rows.reduce((w, r) => Math.max(w, r['Sender']?.length))

    sheet['!cols'] = [{ wch: max_width }]

    // create csv file
    XLSX.writeFile(workbook, `manifests.xlsx`, {
      compression: true
    })
  }

  const onCreateManifest = () => {
    navigate(ROUTES.MANIFESTS.TRANSFER.CREATE.path)
  }

  const handleRowAction = row => {
    navigate(`${ROUTES.MANIFESTS.TRANSFER.path}${row.id}`)
  }

  const rowMenuItems = row => [
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveManifest(row)
        setRaiseException(true)
      }
    },
    {
      name: (
        <AssignToAstrosMenu
          type='MANIFEST'
          data={{
            manifest_type: 'TM',
            id: row.id
          }}
        />
      ),
      icon: AssignUserIcon,
      action: () => {}
    }
  ]

  const handleExceptionModalClose = () => {
    setRaiseException({ isOpen: false, type: '' })
    setActiveManifest(null)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifests'}>
        <Search
          value={searchValue}
          searchBy={searchBy}
          inputPlaceHolder='Search by manifest ID'
          handleSearch={handleSearch}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={ManifestsFilter}
          filter={filter}
          setFilter={setFilter}
        />
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='transfer-manifests-table'
                pageSize={manifestsStore.meta?.page_size}
                totalCount={manifestsStore.meta?.count}
                data={manifestsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={manifestsStore.meta?.page}
              />
            </div>
          </div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <Tabs items={tabs} onSelectTab={onSelectTab} active={tabs[1]} />
            <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
              <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
                <div className='hidden lg:flex'>
                  <Pagination
                    tableId='transfer-manifests-table'
                    pageSize={manifestsStore.meta?.page_size}
                    totalCount={manifestsStore.meta?.count}
                    data={manifestsStore.data}
                    setSerializedData={setSerializedData}
                    onPage={onPage}
                    page={manifestsStore.meta?.page}
                  />
                </div>
                <button
                  className='btn'
                  onClick={exportManifest}
                  disabled={!manifests?.length}
                >
                  <MdOutlineFileDownload size={18} />
                  Export
                </button>
                <Link to={ROUTES.MANIFESTS.TRANSFER.CREATE.path}>
                  <button
                    className='btn btn-primary'
                    // onClick={onCreateManifest}
                  >
                    <IoMdAdd size={18} />
                    Create Manifest
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Table
          id='transfer-manifests-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleRowAction}
          emptyDataText='No manifest found'
          emptyDataActionText='Create manifest'
          emptyDataAction={onCreateManifest}
          withMenu
          rowMenuItems={rowMenuItems}
        />
        {isRaiseException && activeManifest && (
          <RaiseException
            isOpen={isRaiseException}
            onClose={handleExceptionModalClose}
            id={activeManifest.tracking_id}
            type={'MANIFEST'}
          />
        )}
      </Page.Body>
    </Page>
  )
}
