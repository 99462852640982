import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { OrdersFilter, OrderModal, Table } from '../../components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FilterTag from '../../components/globals/filter/FilterTag'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from '../../slices/ordersSlice'
import { fetchBranches } from '../../slices/orgsSlice'
import {
  exportCSVData,
  getDate,
  getStateOfCountry,
  initializeDateRange,
  parseError
} from '../../utils'
import ROUTES from '../../constants/routes'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import { MdOutlineFileDownload } from 'react-icons/md'
import Tabs from '../../components/globals/Tabs'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import { resolveDateParams } from '../../helpers/queryByDate'
import { statuses } from '../../fixtures/orderStatus'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import Pill from '../../components/globals/Pill'
import useToast from '../../hooks/useToast'
import ordersApi from '../../api/orders'
import { CircularProgress } from '@mui/material'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import Filter from '../../components/globals/filter/Filter'
import { IoPaperPlaneSharp } from 'react-icons/io5'

const tabs = [
  {
    name: 'International',
    value: 'IN'
  },
  {
    name: 'Local',
    value: 'LC'
  }
]

const searchOptions = [
  {
    name: 'Order ID',
    value: 'id'
  },
  {
    name: 'Sender',
    value: 'sender_name'
  },
  {
    name: 'Receiver',
    value: 'receiver_name'
  }
]

export default function Orders ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)

  const [searchParams] = useSearchParams()

  const [isModalOpen, setModal] = useState(false)
  const [orderInFocus, setOrderInFocus] = useState()
  const [searchBy, setSearchBy] = useState(searchOptions[0].value)
  const [searchValue, setSearchValue] = useState('')
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [filterTags, setFilterTags] = useState([])
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50,
    type: activeTab.value,
    order_state: searchParams.get('order_state') ?? '',
    branch: ''
  })
  const [filter, setFilter] = useState({
    package_type: '',
    category: '',
    branch: '',
    date: '',
    carrier: '',
    order_state: searchParams.get('order_state') ?? ''
  })
  const [isExportOpen, setExportOpen] = useState(false)
  const [isExportLoading, setExportLoading] = useState(false)

  const ordersStore = useSelector(state => state.orders)
  const categories = useSelector(state => state.categories)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const idQuery = searchParams.get('id')

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const userData = useSelector(state => state.auth.user)

  const toast = useToast()

  const loadOrders = useCallback(
    () => {
      const promise = dispatch(fetchOrders(queryParams))

      return () => {
        promise.abort()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  )

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else if (key === 'order_state') {
          params.order_state = filter[key]
          let tag = { name: 'Status', value: statuses[filter[key]].name }
          tags.push(tag)
        } else if (key === 'package_type') {
          let tag = { name: key, value: '' }
          const packages = {
            regular: 'Regular',
            document: 'Document',
            'fish/snail': 'Fish'
          }
          params.package_type = filter[key]
          tag.value = packages[filter[key]]
          tags.push(tag)
        } else if (key === 'category') {
          let tag = { name: key, value: '' }
          const categories = {
            1: 'Electronics',
            2: 'Non-Elecronics',
            3: 'Haulage'
          }
          tag.value = categories[filter[key]]
          params.category = filter[key]
          tags.push(tag)
        } else if (key === 'branch') {
          let tag = { name: key, value: filter[key] }

          tags.push(tag)
          params.branch = filter[key]
        } else if (key === 'carrier') {
          let tag = { name: 'Carrier', value: filter[key] }
          tags.push(tag)
          params.carrier = filter[key]
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadOrders()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadOrders])

  const onSelectTab = item => {
    setQueryParams(state => ({ ...state, type: item.value }))
    setActiveTab(item)
  }

  const searchInputPlaceHolder = useMemo(() => {
    switch (searchBy) {
      case 'id':
        return 'Enter order ID'
      case 'sender_name':
        return 'Enter sender name'
      case 'receiver_name':
        return 'Enter receiver name'
      default:
        break
    }
  }, [searchBy])

  const orders = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        const results = serializedData.filter(order => {
          const queries = {
            search: {
              apply: !!searchValue,
              match () {
                // eslint-disable-next-line default-case
                switch (searchBy) {
                  case 'id':
                    return order.id
                      .toString()
                      .includes(searchValue.replace('#', ''))
                  case 'sender_name':
                    return order.sender?.contact?.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  case 'receiver_name':
                    return order.receiver?.contact?.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                }
              }
            }
          }

          let matchesQueries = []

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match())
            }
          }

          return matchesQueries.every(match => match)
        })

        return results
      }

      return serializedData
    } else return null
  }, [serializedData, searchValue, searchBy])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const handleSearchOptionChange = option => {
    setSearchBy(option)
    if (serverSearch) {
      setQueryParams(state => {
        delete state[searchBy]
        return state
      })
      setServerSearch(null)
    }
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  useEffect(() => {
    if (idQuery && serializedData) {
      setOrderInFocus(idQuery)
      setModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idQuery, serializedData, activeTab.value])

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleOnClickOrder = row => {
    setOrderInFocus(row.id)
    setModal(true)
  }

  const onFilterDelete = key => {
    key = key === 'Status' ? 'order_state' : key
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const onCreateOrder = () => {
    window.sessionStorage.removeItem('order')
    window.sessionStorage.removeItem('orderSummary')
    window.sessionStorage.removeItem('multipiece')
    window.sessionStorage.removeItem('multipieceOrders')

    navigate(ROUTES.ORDERS.CREATE_ORDER.path)
  }

  const exportOrders = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await ordersApi.exportOrders(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `orders_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'orders.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportOrders(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const canCreateOrder = useMemo(() => {
    return userRole?.permissions?.orders?.create
  }, [userRole])

  const tableHeader = useMemo(() => {
    return [
      'S/N',
      'ID',
      'Weight',
      'Date',
      'Amount',
      'Origin',
      'Destination',
      activeTab.value === 'LC' ? 'Category' : 'Package Type',
      'Carrier',
      'Branch',
      'Status'
    ]
  }, [activeTab.value])

  const tableData = useMemo(() => {
    if (orders && categories?.data) {
      return orders.map(order => ({
        ...order,
        'S/N': order.s_n,
        ID: `#${order.id}`,
        Weight: `${order.weight} kg`,
        Date: getDate(order.order_date),
        Amount: order.total.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        Origin: `${
          order?.sender?.address?.state_name ||
          getStateOfCountry(
            order?.sender?.address?.state_or_province_code,
            order?.sender?.address?.country
          )?.name ||
          order?.sender?.address?.state_or_province_code
        }, ${order?.sender?.address?.country}`,
        Destination: `${
          order?.receiver?.address?.state_name ||
          getStateOfCountry(
            order?.receiver?.address?.state_or_province_code,
            order?.receiver?.address?.country
          )?.name ||
          order?.receiver?.address?.state_or_province_code
        }, ${order?.receiver?.address?.country}`,
        ...(activeTab.value === 'LC'
          ? {
              Category: categories?.data.find(
                catgry => catgry.id === order.category
              )?.name
            }
          : {
              'Package Type':
                order?.packages?.type === 'regular'
                  ? 'Package'
                  : order.packages?.type
            }),
        Carrier: order.carrier,
        Branch: order.branch_name,
        Status: (
          <Pill
            name={statuses[order.order_state].name}
            theme={statuses[order.order_state].theme}
          />
        )
      }))
    }
  }, [activeTab.value, categories?.data, orders])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Orders'}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={handleSearchOptionChange}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={OrdersFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
          type={activeTab.value}
        />
      </Page.Header>
      <Page.Body>
        {isModalOpen && (
          <OrderModal
            isOpen={isModalOpen}
            id={orderInFocus}
            onClose={() => setModal(false)}
            onUpdateOrders={loadOrders}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='orders'
            onClose={closeExport}
            options={queryParams}
            onExport={exportOrders}
          />
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='orders-table'
                pageSize={ordersStore.meta?.page_size}
                totalCount={ordersStore.meta?.count}
                data={ordersStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={ordersStore.meta?.page}
              />
            </div>
          </div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <Tabs items={tabs} onSelectTab={onSelectTab} active={activeTab} />
            <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='orders-table'
                  totalCount={ordersStore.meta?.count}
                  data={ordersStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={ordersStore.meta?.page}
                  pageSize={ordersStore.meta?.page_size}
                />
              </div>
              <button className='btn' onClick={handleExport} disabled>
                <MdOutlineFileDownload size={18} />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress size={18} color='inherit' />
                  </>
                ) : (
                  'Export'
                )}
              </button>
              {canCreateOrder && (
                <button onClick={onCreateOrder} className='btn btn-primary'>
                  <IoPaperPlaneSharp size={18} />
                  Create
                </button>
              )}
            </div>
          </div>
        </div>

        <Table
          id='orders-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleOnClickOrder}
          emptyDataText='No order found'
          emptyDataActionText='Create new order'
          emptyDataAction={onCreateOrder}
        />
      </Page.Body>
    </Page>
  )
}
