import React, { useState, useEffect, useMemo } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import useToast from '../../../hooks/useToast'
import { ReactComponent as User } from '../../../assets/assign-user.svg'
import { BackButton, Button, Table } from '../../../components'
import Page from '../../../containers/Page'
import manifestApi from '../../../api/manifests'
import { capitalizeFirstLetter, parseError } from '../../../utils'
import useApi from '../../../hooks/useApi'
import ROUTES from '../../../constants/routes'
import Search from '../../../components/globals/Search/Search'
import { AssignUserIcon, ExceptionIcon } from '../../../components/icons'
import { MdOutlinePreview } from 'react-icons/md'
import { AiOutlineDelete } from 'react-icons/ai'
import { AssignToAstro, AssignToAstrosMenu } from '../../../components/astros'
import RaiseException from '../../../components/exception/RaiseException'
import { ConfirmPrompt } from '../../../components/globals'
import { CircularProgress } from '@mui/material'
import useCheckedList from '../../../hooks/useCheckedList'

const groupsTableHeader = [
  'S/N',
  'Group waybill',
  'Destination',
  'No. Of Bags',
  'Arrived Destination'
]

const shipmentsTableHeader = ['S/N', 'Shipment']

const IndividualTransferManifest = ({ metaTitle }) => {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'Transfer Manifests',
      path: ROUTES.MANIFESTS.TRANSFER.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: '#',
      disabled: true
    }
  ]
  const { data, request: loadManifest } = useApi(
    manifestApi.getManifest,
    `TM/${id}`
  )
  const manifest = useMemo(() => data?.payload, [data?.payload])

  const navigate = useNavigate()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()

  const [search, setSearch] = useState({
    by: 'tracking_id',
    value: ''
  })
  const [raiseException, setRaiseException] = useState({
    isOpen: false,
    type: ''
  })

  const [isAssign, setAssign] = useState(false)
  const [activeGroup, setActiveGroup] = useState()
  const [activeBag, setActiveBag] = useState()
  const [activeShipment, setActiveShipment] = useState(null)
  const [isRemove, setRemove] = useState({
    isOpen: false,
    isLoading: false,
    type: ''
  })

  useEffect(() => {
    const destination = searchParams.get('destination')
    const bag = searchParams.get('bag')
    if (destination && bag && manifest) {
      const group = manifest.meta.groups.find(
        group => group.destination === destination
      )
      if (group) {
        const _bag = group.bags.find(({ id, tag }) => tag === bag || id === bag)
        if (_bag) {
          setActiveGroup(group)
          setActiveBag(_bag)
          return
        }
      }

      setActiveGroup(null)
      setActiveBag(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifest, searchParams])

  const groupsTableData = useMemo(() => {
    return manifest?.meta.groups.map((group, groupIdx) => ({
      s_n: groupIdx + 1,
      'S/N': groupIdx + 1,
      'Group waybill': (
        <div>
          {group.bags?.map((bag, bagIdx) => (
            <span
              key={`bag-${bagIdx}`}
              className='cursor-pointer hover:underline hover:text-primary block'
              role='button'
              onClick={() => {
                setSearchParams(state => ({
                  ...state,
                  destination: group.destination,
                  bag: bag.tag || bag.id
                }))
              }}
            >
              {bag.tag?.toUpperCase() || bag.id?.toUpperCase()}
            </span>
          ))}
        </div>
      ),
      Destination: group.destination,
      'No. Of Bags': group.number_of_bags,
      'Arrived Destination': group.arrived_at_destination ? 'TRUE' : 'FALSE',
      ...group
    }))
  }, [manifest, setSearchParams])

  const shipmentsTableData = useMemo(() => {
    return activeBag?.shipments.map((shipment, shipmentIdx) => ({
      s_n: shipmentIdx + 1,
      'S/N': shipmentIdx + 1,
      Shipment: (
        <Link
          to={`${ROUTES.SHIPMENTS.path}?id=${shipment}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          {shipment.toUpperCase()}
        </Link>
      ),
      tracking_id: shipment.toUpperCase()
    }))
  }, [activeBag])

  const { checkedList, setCheckedList, selectedItems } = useCheckedList({
    serializedData:
      activeBag && activeGroup ? shipmentsTableData : groupsTableData
  })

  const credentials = useMemo(
    () => [
      {
        key: 'Orginating Hub',
        value: manifest?.originating_hub_name
      },
      {
        key: 'Destination Hub',
        value: manifest?.destination_hub_name
      },
      {
        key: 'Astro/Captain',
        value: manifest?.assigned_to?.name || (
          <span className='italic'>Unassigned</span>
        )
      }
    ],
    [manifest]
  )

  useEffect(() => {
    loadManifest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCheckedList({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBag && activeGroup])

  const groupsRowMenuItems = row => [
    {
      name: 'Create Door-step manifest',
      icon: User,
      action: () => {
        navigate(ROUTES.MANIFESTS.DOORSTEP.CREATE.path)
      }
    },
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveGroup(row)
        setRaiseException({ isOpen: true, type: 'BAG' })
      }
    },
    {
      name: 'Remove Group',
      icon: AiOutlineDelete,
      action: () => {
        setActiveGroup(row)
        setRemove({
          isOpen: true,
          isLoading: false,
          type: 'GROUP',
          content: 'single'
        })
      }
    }
  ]

  const shipmentsMenuItems = row => [
    {
      name: (
        <AssignToAstrosMenu
          type='SHIPMENT'
          data={{
            id: row.tracking_id
          }}
        />
      ),
      icon: AssignUserIcon,
      action: (row, closePanel) => {
        setActiveShipment(row)
      }
    },
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveShipment(row)
        setRaiseException({ isOpen: true, type: 'BAG' })
      }
    },
    {
      name: 'Remove Shipment',
      icon: AiOutlineDelete,
      action: () => {
        setActiveShipment(row)
        setRemove({
          isOpen: true,
          isLoading: false,
          type: 'SHIPMENT',
          content: 'single'
        })
      },
      color: 'danger'
    }
  ]

  const handleEditManifest = () => {
    // setAddShipments(true)
  }

  const handleExceptionModalClose = () => {
    setRaiseException({ isOpen: false, type: '' })
    setActiveShipment(null)
  }
  const handleSearch = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }
  const handleAssignClose = ({ isSuccess }) => {
    setAssign(false)
    if (isSuccess) {
      loadManifest()
    }
  }

  const handleAssign = () => {
    if (!manifest.meta.groups.length) {
      toast('Manifest is empty!', 'error')
      return
    }
    setAssign(true)
  }

  const handleRemove = async () => {
    if (isRemove.isLoading) return

    setRemove(state => ({ ...state, isLoading: true }))

    let response

    const shipments = (() => {
      switch (isRemove.type) {
        case 'SHIPMENT':
          switch (isRemove.content) {
            case 'single':
              return [activeShipment.tracking_id]
            case 'multiple':
              return selectedItems.map(item => item.tracking_id)
            default:
              return []
          }
        case 'BAG':
          switch (isRemove.content) {
            case 'single':
              return activeBag.shipments
            case 'multiple':
              return selectedItems.reduce(
                (acc, item) => [...acc, ...item.shipments],
                []
              )
            default:
              return []
          }
        case 'GROUP':
          switch (isRemove.content) {
            case 'single':
              return activeGroup.bags.reduce(
                (acc, bag) => [...acc, ...bag.shipments],
                []
              )
            case 'multiple':
              return selectedItems.reduce(
                (acc, item) => [
                  ...acc,
                  ...item.bags.reduce(
                    (acc, bag) => [...acc, ...bag.shipments],
                    []
                  )
                ],
                []
              )
            default:
              return []
          }
        default:
          return []
      }
    })()

    response = await manifestApi.removeShipment({
      manifest_id: id,
      shipments,
      manifest_type: 'TM'
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast(`${capitalizeFirstLetter(isRemove.type)} removed`, 'success')
    closeRemovePrompt()
    loadManifest()
  }

  const closeRemovePrompt = () => {
    setActiveShipment(null)
    setRemove({ isOpen: false, isLoading: false, type: '' })
  }

  return (
    <Page metaTitle={`${tracking_id.toUpperCase()} - ${metaTitle}`}>
      <Page.Header
        title={tracking_id.toUpperCase()}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.TRANSFER.path}
      >
        <Search
          value={search.value}
          searchBy={search.by}
          inputPlaceHolder='Search by shipment ID'
          handleSearch={handleSearch}
          allowServerSearch={false}
        />
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'></div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div></div>
            <div className='flex flex-wrap items-center justify-end gap-2 lg:gap-3 ml-auto'>
              <Button
                variant='neutral'
                text='Raise Exception'
                onClick={() =>
                  setRaiseException({ isOpen: true, type: 'MANIFEST' })
                }
                icon={<ExceptionIcon width={14} height={14} />}
              />
              <Link
                to={
                  !manifest?.meta.groups.length
                    ? '#'
                    : `${ROUTES.MANIFESTS.TRANSFER.path}${id}/sheet`
                }
              >
                <Button
                  variant='neutral'
                  text='Preview'
                  icon={<MdOutlinePreview />}
                />
              </Link>
              <Button
                text={manifest?.assigned_to ? 'Reassign' : 'Assign'}
                icon={<AssignUserIcon stroke='white' width='14' />}
                onClick={handleAssign}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='text-sm py-4 flex w-full flex-col md:flex-row md:gap-10 items-start'>
            <div className='space-y-1 mb-1'>
              {credentials.slice(0, 3).map(({ key, value }, id) => (
                <div className='flex gap-2 w-full' key={id}>
                  <p className='w-52'>{key}:</p>
                  <p className='w-[calc(100%_-_13rem)] font-medium break-all capitalize'>
                    {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='relative w-full pb-20'>
          <div className='flex items-center gap-3 mb-2'>
            {activeBag && activeGroup && (
              <div className='flex gap-3 items-center'>
                <BackButton
                  onBack={e => {
                    e.preventDefault()
                    setActiveBag(null)
                    setActiveGroup(null)
                  }}
                />
                <span>
                  Bag - {(activeBag.tag || activeBag.id)?.toUpperCase()}
                </span>
              </div>
            )}
            {selectedItems.length ? (
              <div className='text-sm'>
                <div className='flex gap-2 items-center'>
                  <p>
                    Selected {selectedItems.length} item
                    {selectedItems.length > 1 ? 's' : ''}
                  </p>
                  •
                  <button
                    className='p-1 btn btn-error btn-outline btn-sm'
                    onClick={() =>
                      setRemove({
                        isOpen: true,
                        isLoading: false,
                        type: activeBag && activeGroup ? 'SHIPMENT' : 'GROUP',
                        content: 'multiple'
                      })
                    }
                  >
                    <AiOutlineDelete />
                    Remove Shipments
                    {isRemove.isLoading && isRemove.type === 'multiple' && (
                      <CircularProgress color='inherit' />
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          {activeBag && activeGroup ? (
            <Table
              id={`${activeBag.id}-shipments-table`}
              headers={shipmentsTableHeader}
              data={shipmentsTableData}
              emptyDataText='No shipment found in bag'
              emptyDataActionText='Add Shipment'
              emptyDataAction={handleEditManifest}
              withMenu
              rowMenuItems={shipmentsMenuItems}
              withCheckbox
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          ) : (
            <>
              <Table
                id={`${tracking_id}-groups-table`}
                headers={groupsTableHeader}
                data={groupsTableData}
                emptyDataText='No shipment found in manifest'
                emptyDataActionText='Add Shipment'
                emptyDataAction={handleEditManifest}
                withMenu
                rowMenuItems={groupsRowMenuItems}
                withCheckbox
                checkedList={checkedList}
                setCheckedList={setCheckedList}
              />
            </>
          )}
          {isAssign && (
            <AssignToAstro
              isOpen={isAssign}
              data={{
                manifest_type: 'TM',
                id
              }}
              onClose={handleAssignClose}
              type='MANIFEST'
            />
          )}
          {raiseException.isOpen && (
            <RaiseException
              isOpen={raiseException.isOpen}
              onClose={handleExceptionModalClose}
              id={
                raiseException.type === 'SHIPMENT'
                  ? activeShipment.tracking_id
                  : tracking_id
              }
              type={raiseException.type}
            />
          )}
          {isRemove.isOpen && (
            <ConfirmPrompt
              isOpen={isRemove.isOpen}
              onConfirm={handleRemove}
              onCancel={closeRemovePrompt}
              title='Confirm Removal'
              text={`Are you sure you want to remove ${
                isRemove.type === 'multiple'
                  ? 'the selected shipments'
                  : 'this shipment'
              } from the manifest?`}
              contentLabel='confirm remove shipment'
              isLoading={isRemove.isLoading}
            />
          )}
        </div>
      </Page.Body>
    </Page>
  )
}

export default IndividualTransferManifest
