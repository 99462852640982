export const statuses = {
  PUBLISH: {
    name: 'Published',
    theme: {
      primary: '#61A966',
      secondary: '#D8F7DA99'
    }
  },
  DRAFT: {
    name: 'Draft',
    theme: {
      primary: '#BDBDBD',
      secondary: '#F2F2F2'
    }
  }
}
