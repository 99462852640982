import hmsClient from './clients/hmsClient'
import tmsClient from './clients/tmsClient'
import bookingClient from './clients/bookingClient'
import { getUrlQueryString } from '../utils'

const endpoint = 'manifests/'

const getCenterManifests = async params => {
  const search_params = getUrlQueryString(params)

  const response = await hmsClient.get(
    `${endpoint}get_center_manifests?${search_params}`
  )
  return response
}

const getTransferManifests = async params => {
  const search_params = getUrlQueryString(params)

  const response = await hmsClient.get(
    `${endpoint}get_transfer_manifests?${search_params}`
  )
  return response
}

const getDoorStepManifests = async params => {
  const search_params = getUrlQueryString(params)

  const response = await hmsClient.get(
    `${endpoint}get_doorstep_manifests?${search_params}`
  )
  return response
}

const getInternationalManifests = async params => {
  const search_params = getUrlQueryString(params)

  const response = await hmsClient.get(
    `${endpoint}get_international_manifests?${search_params}`
  )
  return response
}

const getRiders = async () => {
  const response = await tmsClient.get(`riders/`)
  return response
}

const scanManifest = async body => {
  const response = await hmsClient.post(`scan/scan_manifest/`, body)
  return response
}

const scanShipment = async body => {
  const response = await hmsClient.post(`scan/scan_shipment/`, body)
  return response
}

const getManifest = async data => {
  const response = await hmsClient.get(`${endpoint}${data}`)
  return response
}

const publishManifest = async data => {
  const response = await hmsClient.patch(`${endpoint}${data}/publish/`)
  return response
}

const triggerCreateCenterManifest = async body => {
  const response = await bookingClient.post(
    'shipments/create_centre_manifest/',
    body
  )
  return response
}

const triggerRedstarExpress = async body => {
  const response = await bookingClient.post(
    'shipments/create_rse_centre_manifest/',
    body
  )
  return response
}

const addShipment = async body => {
  const response = await hmsClient.post(`manifests/add_shipments/`, body)
  return response
}

const removeShipment = async body => {
  const response = await hmsClient.post(`manifests/remove_shipments/`, body)
  return response
}

const getAllBags = async data => {
  const response = await hmsClient.get(`${data}`)
  return response
}

const getAllClosedBags = async () => {
  const response = await hmsClient.get(`bags/get_closed_bags/`)
  return response
}

const getExceptions = async () => {
  const response = await hmsClient.get('scan/exceptions/')
  return response
}

const createManifest = async body => {
  const response = await hmsClient.post(endpoint, body)
  return response
}

const assignManifest = async (data, body) => {
  const response = await hmsClient.patch(
    `${endpoint}${data}/assign_manifest/`,
    body
  )
  return response
}

const getHubs = async () => {
  const response = await hmsClient.get(`hubs/`)
  return response
}

const createDoorStep = async body => {
  const response = await bookingClient.post(
    `/shipments/build_delivery_packages/`,
    body
  )
  return response
}

const bulkTracking = async param => {
  const response = await hmsClient.get(`${endpoint}${param}/track/`)
  return response
}

const removeShipmentFromBag = async (body, params) => {
  const response = await hmsClient.patch(
    `bags/${params}/remove_shipments/`,
    body
  )
  return response
}

const getPackingList = async manifestId => {
  const response = await hmsClient.post('parkinglists/', {
    manifest: manifestId
  })
  return response
}

const editPackingList = async (packingListId, body) => {
  const response = await hmsClient.patch(`parkinglists/${packingListId}/`, body)

  return response
}

const createCommercialInvoice = async packingListId => {
  const response = await hmsClient.post(
    `parkinglists/${packingListId}/create_commercial_invoice/`
  )

  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCenterManifests,
  getTransferManifests,
  getDoorStepManifests,
  getInternationalManifests,
  getManifest,
  createManifest,
  getHubs,
  assignManifest,
  getRiders,
  getAllBags,
  createDoorStep,
  getExceptions,
  scanManifest,
  scanShipment,
  addShipment,
  removeShipment,
  triggerCreateCenterManifest,
  triggerRedstarExpress,
  publishManifest,
  getAllClosedBags,
  bulkTracking,
  removeShipmentFromBag,
  getPackingList,
  editPackingList,
  createCommercialInvoice,
}
